import React from 'react';
import { PageProps } from 'gatsby';
import SEO from '~/components/seo';
import { isFeatureOn, Feature } from '~/services/featureFlag';
import { useNavigate } from '~/state/hooks/useNavigate';
import { Testing } from '~/components/Testing';

const TestingPage: React.FC<PageProps> = props => {
  const { navigate } = useNavigate();
  if (!isFeatureOn(Feature.RAPID_TESTING)) {
    navigate('/');
    return null;
  }
  return (
    <>
      <SEO pageContext={props.pageContext} title="Testing.pageTitle" />
      <Testing />
    </>
  );
};

export default TestingPage;
