/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  USState,
  CanadaProvince,
  OtherCovidTestAnswer,
  WasTestedForCovidAnswer,
} from './types';

export const receivedCovidTest = (answer: WasTestedForCovidAnswer): boolean => {
  return answer === WasTestedForCovidAnswer.YES;
};

export const receivedFollowUpTest = (answer: OtherCovidTestAnswer): boolean => {
  return (
    answer === OtherCovidTestAnswer.YES_PCR_TEST ||
    answer === OtherCovidTestAnswer.YES_BUT_UNSURE_ABOUT_TYPE
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getEnumKeys = (enumeration: any): string[] => {
  const enumKeys = [];
  // eslint-disable-next-line guard-for-in
  for (const value in enumeration) {
    enumKeys.push(value);
  }
  return enumKeys;
};

const usStates = getEnumKeys(USState);
const canadaProvinces = getEnumKeys(CanadaProvince);

export const getCountryByStateOrProvince = (
  stateOrProvinceField: USState | CanadaProvince
): 'us' | 'ca' | undefined => {
  if (usStates.some(option => stateOrProvinceField === option)) {
    return 'us';
  }
  if (canadaProvinces.some(option => stateOrProvinceField === option)) {
    return 'ca';
  }
};
