/* eslint-disable camelcase */

export enum YesOrNoAnswer {
  YES = 'YES',
  NO = 'NO',
}

export enum WasTestedForCovidAnswer {
  YES = 'YES',
  OTHER_TEST = 'OTHER_TEST',
  NO = 'NO',
}

export enum OtherCovidTestAnswer {
  YES_PCR_TEST = 'YES_PCR_TEST',
  YES_BUT_UNSURE_ABOUT_TYPE = 'YES_BUT_UNSURE_ABOUT_TYPE',
  NO_BUT_HAVE_ONE_SCHEDULED = 'NO_BUT_HAVE_ONE_SCHEDULED',
  NO = 'NO',
}

export enum CovidTestResultAnswer {
  POSITIVE = 'POSITIVE',
  NEGATIVE = 'NEGATIVE',
  OTHER = 'OTHER',
}

export enum AgeAnswer {
  EMPTY = '',
  '0-4' = '0-4',
  '5-10' = '5-10',
  '11-14' = '11-14',
  '15-18' = '15-18',
  '18-25' = '18-25',
  '26-34' = '26-34',
  '35-44' = '35-44',
  '45-54' = '45-54',
  '55-64' = '55-64',
  '65+' = '65 or older',
}

export enum RapidTestFormField {
  was_tested = 'was_tested',
  test_brand = 'test_brand',
  access_rapid_tests = 'access_rapid_tests',
  test_date = 'test_date',
  test_result = 'test_result',
  symptoms_in_last_14_days = 'symptoms_in_last_14_days',
  shared_test_results = 'shared_test_results',
  other_covid_test = 'other_covid_test',
  other_test_date = 'other_test_date',
  other_test_result = 'other_test_result',
  age = 'age',
  state_or_province = 'state_or_province',
  zipcode = 'zipcode',
}

export enum USState {
  AK = 'AK',
  AL = 'AL',
  AZ = 'AZ',
  AR = 'AR',
  CA = 'CA',
  CO = 'CO',
  CT = 'CT',
  DE = 'DE',
  DC = 'DC',
  FL = 'FL',
  GA = 'GA',
  HI = 'HI',
  ID = 'ID',
  IL = 'IL',
  IN = 'IN',
  IA = 'IA',
  KS = 'KS',
  KY = 'KY',
  LA = 'LA',
  ME = 'ME',
  MD = 'MD',
  MA = 'MA',
  MI = 'MI',
  MN = 'MN',
  MS = 'MS',
  MO = 'MO',
  MT = 'MT',
  NE = 'NE',
  NV = 'NV',
  NH = 'NH',
  NJ = 'NJ',
  NM = 'NM',
  NY = 'NY',
  NC = 'NC',
  ND = 'ND',
  OH = 'OH',
  OK = 'OK',
  OR = 'OR',
  PA = 'PA',
  PR = 'PR',
  RI = 'RI',
  SC = 'SC',
  SD = 'SD',
  TN = 'TN',
  TX = 'TX',
  UT = 'UT',
  VT = 'VT',
  VA = 'VA',
  WA = 'WA',
  WV = 'WV',
  WI = 'WI',
  WY = 'WY',
}

export enum CanadaProvince {
  AB = 'AB',
  BC = 'BC',
  MB = 'MB',
  NB = 'NB',
  NL = 'NL',
  NT = 'NT',
  NS = 'NS',
  NU = 'NU',
  ON = 'ON',
  PE = 'PE',
  QC = 'QC',
  SK = 'SK',
  YT = 'YT',
}

export enum TestBrand {
  BinaxNOW = 'BinaxNOW',
  DxTerity = 'DxTerity',
  Ellume = 'Ellume',
  Everlywell = 'Everlywell',
  FlowFlex = 'FlowFlex',
  iHealth = 'iHealth',
  'Labcorp pixel' = 'Labcorp pixel',
  LetsGetChecked = 'LetsGetChecked',
  'Lucira Check It' = 'Lucira Check It',
  'On_Go' = 'On_Go',
  QuickVue = 'QuickVue',
  Other = 'Other',
  Unsure = 'Unsure',
}

export enum AccessToRapidTestAnswer {
  PURCHASED_AT_STORE_OR_PHARMACY = 'PURCHASED_AT_STORE_OR_PHARMACY',
  GOVERNMENT_PROVIDED = 'GOVERNMENT_PROVIDED',
  THROUGH_INSURANCE = 'THROUGH_INSURANCE',
  FROM_DOCTOR = 'FROM_DOCTOR',
  FROM_WORK = 'FROM_WORK',
  FROM_SCHOOL = 'FROM_SCHOOL',
  FROM_FAMILY_OR_FRIEND = 'FROM_FAMILY_OR_FRIEND',
  OTHER = 'OTHER',
}

export enum SharedTestResultsAnswer {
  YES = 'YES',
  NO = 'NO',
  NOT_YET = 'NOT_YET',
}

export interface RapidTestForm {
  [RapidTestFormField.was_tested]: WasTestedForCovidAnswer;
  [RapidTestFormField.test_brand]: TestBrand;
  [RapidTestFormField.access_rapid_tests]: AccessToRapidTestAnswer;
  [RapidTestFormField.test_date]: string;
  [RapidTestFormField.test_result]: CovidTestResultAnswer;
  [RapidTestFormField.symptoms_in_last_14_days]: YesOrNoAnswer;
  [RapidTestFormField.shared_test_results]: SharedTestResultsAnswer;
  [RapidTestFormField.other_covid_test]: OtherCovidTestAnswer;
  [RapidTestFormField.other_test_date]: string;
  [RapidTestFormField.other_test_result]: CovidTestResultAnswer;
  [RapidTestFormField.age]: AgeAnswer;
  [RapidTestFormField.state_or_province]: USState | CanadaProvince;
  [RapidTestFormField.zipcode]: string;
}
