import React from 'react';
import { RegisterOptions, Control, Controller } from 'react-hook-form';
import { KeyboardDatePickerProps } from '@material-ui/pickers';
import { DatePicker } from '~/components/forms/fields';

interface Props extends KeyboardDatePickerProps {
  errorMessage: string;
  onChange: (date: Date, value?: string) => void;
  validationRules: RegisterOptions;
  formControl: Control;
  name: string;
}

export const ControlledDatePicker: React.FC<Props> = ({
  errorMessage,
  id,
  onChange,
  validationRules,
  formControl,
  name,
  ...props
}) => {
  return (
    <Controller
      name={name}
      control={formControl}
      rules={validationRules}
      render={() => (
        <DatePicker
          name={name}
          id={id}
          errorMessage={errorMessage}
          onChange={onChange}
          {...props}
        />
      )}
    />
  );
};
