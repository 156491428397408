import { RapidTestForm } from '~/components/Testing/types';
import { httpClient } from '~/services/http';

interface Data {
  errorMessage?: string;
  errorType?: string;
}

interface Response {
  data: Data;
}

const RAPID_TESTS_SURVEY_API_URL =
  process.env.GATSBY_RAPID_TESTS_SURVEY_API_URL;

export const submitTestingForm = (
  requestBody: RapidTestForm,
  recaptchaToken: string
): Promise<Response> => {
  return httpClient.post(RAPID_TESTS_SURVEY_API_URL, requestBody, {
    headers: { recaptchaToken },
  }) as Promise<Response>;
};
