import React from 'react';
import { useTranslation } from 'react-i18next';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import HelperText from '@material-ui/core/FormHelperText';
import { Select as MuiSelect, SelectProps, useTheme } from '@material-ui/core';
import { RegisterOptions, Control, Controller } from 'react-hook-form';

export type SelectOptions = { value: string; label: string }[];

interface Props extends SelectProps {
  formControl: Control;
  validationRules: RegisterOptions;
  errorMessage?: string;
}

export const StatesAndProvincesSelect: React.FC<Props> = ({
  name,
  label,
  formControl,
  errorMessage,
  validationRules,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const tPath = 'Testing.form.state_or_province';

  const unitedStatesOptions: SelectOptions = t(`${tPath}.options.US`, {
    returnObjects: true,
  });

  const canadaOptions: SelectOptions = t(`${tPath}.options.CA`, {
    returnObjects: true,
  });

  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel id={`${name}_label`}>{label}</InputLabel>
      <Controller
        name={name}
        defaultValue=""
        control={formControl}
        rules={validationRules}
        render={props => (
          <MuiSelect
            native
            label={label}
            inputRef={props.ref}
            error={!!errorMessage}
            labelId={`${name}_label`}
            style={{ backgroundColor: theme.palette.common.white }}
            {...props}
          >
            <option key="" value="" />
            <optgroup label="United States">
              {unitedStatesOptions.map(opt => (
                <option key={opt.value} value={opt.value}>
                  {opt.label}
                </option>
              ))}
            </optgroup>
            <optgroup label="Canada">
              {canadaOptions.map(opt => (
                <option key={opt.value} value={opt.value}>
                  {opt.label}
                </option>
              ))}
            </optgroup>
          </MuiSelect>
        )}
      />
      {errorMessage && (
        <HelperText error id={`${name}_error`}>
          {errorMessage}
        </HelperText>
      )}
    </FormControl>
  );
};
