import React from 'react';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import HelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
  RadioGroup,
  RadioGroupProps,
  Radio as MuiRadio,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

export type RadioOptions = { value: string; label: string }[];

interface Props extends RadioGroupProps {
  value: string;
  options: RadioOptions;
  onChange: (_: unknown, value: string) => void;
  errorMessage?: string;
  label?: string;
}

export const Radio: React.FC<Props> = ({
  row,
  name,
  label,
  value,
  options,
  onChange,
  errorMessage,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <FormControl fullWidth margin="normal" component="fieldset">
      {label && (
        <FormLabel component="h1" style={{ color: theme.palette.text.primary }}>
          {label}
        </FormLabel>
      )}
      <RadioGroup
        name={name}
        value={value}
        aria-label={name}
        onChange={onChange}
        row={row !== undefined ? row : !isMobile}
      >
        {options.map(option => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            label={option.label}
            control={<MuiRadio />}
          />
        ))}
      </RadioGroup>
      <HelperText error id={`${name}_error`}>
        {errorMessage}
      </HelperText>
    </FormControl>
  );
};
