import React from 'react';
import { ExternalLink, P, Section } from '~/components/BaseElements';
import { Trans, useTranslation } from 'react-i18next';
import { submitTestingForm } from '~/requests/testing';
import { useSnackbar } from '~/state/hooks/useSnackbar';
import { useNavigate } from '~/state/hooks/useNavigate';
import { useLanguageUrl } from '~/state/hooks/useLanguageUrl';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { RadioGroup } from './RadioGroup';
import Hero from '~/components/Hero/index';
import Container from '@material-ui/core/Container';
import { useRapidTestForm } from './useRapidTestForm';
import { ControlledDatePicker } from './ControlledDatePicker';
import { StatesAndProvincesSelect } from './StatesAndProvincesSelect';
import { Select, SelectOptions } from '~/components/forms/fields/Select';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { RapidTestForm, RapidTestFormField } from './types';
import { convertDateToISO } from '~/common/generalUtils';
import { logger, ONMFeature } from '~/services/logging';
import { ROUTES } from '~/config';
import { Button, TextField } from '@material-ui/core';
import {
  getCountryByStateOrProvince,
  receivedCovidTest,
  receivedFollowUpTest,
} from './helpers';

export const Testing: React.FC = () => {
  const { t } = useTranslation();
  const languageUrl = useLanguageUrl();
  const { showSnackbar } = useSnackbar();
  const { navigate } = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const tPath = 'Testing';

  const {
    form,
    validationSchema,
    wasTestedField,
    otherCovidTestField,
    stateOrProvinceField,
  } = useRapidTestForm();

  const invalidSubmit = () => {
    showSnackbar(t(`${tPath}.form.invalidError`), 'error');
  };

  const onSubmit = async (formValues: RapidTestForm) => {
    try {
      const recaptchaToken = await executeRecaptcha();
      if (!recaptchaToken) {
        throw new Error('reCaptcha failed!');
      }
      const requestBody = {
        [RapidTestFormField.was_tested]: formValues.was_tested,
        [RapidTestFormField.test_brand]: formValues.test_brand || null,
        [RapidTestFormField.test_date]: formValues.test_date
          ? convertDateToISO(formValues.test_date)
          : null,
        [RapidTestFormField.test_result]: formValues.test_result || null,
        [RapidTestFormField.symptoms_in_last_14_days]:
          formValues.symptoms_in_last_14_days || null,
        [RapidTestFormField.access_rapid_tests]:
          formValues.access_rapid_tests || null,
        [RapidTestFormField.shared_test_results]:
          formValues.shared_test_results || null,
        [RapidTestFormField.other_covid_test]:
          formValues.other_covid_test || null,
        [RapidTestFormField.other_test_date]: formValues.other_test_date
          ? convertDateToISO(formValues.other_test_date)
          : null,
        [RapidTestFormField.other_test_result]:
          formValues.other_test_result || null,
        [RapidTestFormField.age]: formValues.age || null,
        [RapidTestFormField.state_or_province]:
          formValues.state_or_province || null,
        [RapidTestFormField.zipcode]: formValues.zipcode || null,
      };

      const { data } = await submitTestingForm(requestBody, recaptchaToken);
      if (data.errorMessage) {
        throw Error();
      }

      showSnackbar(t(`${tPath}.postSuccess`), 'success', () => {
        navigate('', { replace: true });
      });
    } catch (error) {
      logger.error(ONMFeature.RAPID_TEST_SURVEY, error);
      showSnackbar(t('ErrorHandling.submissionErrors.ServerError'), 'error');
    }
  };

  const getFieldError = (fieldName: RapidTestFormField) => {
    const errorType = form?.errors?.[fieldName]?.type;
    return t(`${tPath}.form.${fieldName}.errors.${errorType}`, {
      defaultValue: '',
    });
  };

  const ageOptions: SelectOptions = t(`${tPath}.form.age.options`, {
    returnObjects: true,
  });

  const testBrandOptions: SelectOptions = t(
    `${tPath}.form.test_brand.options`,
    { returnObjects: true }
  );

  const accessToTestOptions: SelectOptions = t(
    `${tPath}.form.access_rapid_tests.options`,
    { returnObjects: true }
  );

  const getFieldProps = (fieldName: RapidTestFormField) => ({
    name: fieldName,
    formControl: form.control,
    errorMessage: getFieldError(fieldName),
    label: t(`${tPath}.form.${fieldName}.label`),
    validationRules: validationSchema.get(fieldName),
  });

  return (
    <Section py={0}>
      <Hero isSubPage>{t(`${tPath}.title`)}</Hero>
      <Box py={8}>
        <Container>
          <Grid item md={8}>
            <P>{t(`${tPath}.p1`)}</P>
            <P>
              <Trans i18nKey={`${tPath}.p2`}>
                <ExternalLink to={`${languageUrl}${ROUTES.privacyPolicy}`} />
              </Trans>
            </P>
            <P>{t(`${tPath}.p3`)}</P>
          </Grid>
          <Grid container direction="column" spacing={3} item>
            <Grid item md={8}>
              <RadioGroup
                row={false}
                {...getFieldProps(RapidTestFormField.was_tested)}
              />
            </Grid>
            {receivedCovidTest(wasTestedField) && (
              <>
                <Grid item md={8}>
                  <Select
                    showBlankDefault
                    variant="outlined"
                    options={testBrandOptions}
                    {...getFieldProps(RapidTestFormField.test_brand)}
                  />
                </Grid>
                <Grid item md={8}>
                  <Select
                    showBlankDefault
                    variant="outlined"
                    options={accessToTestOptions}
                    {...getFieldProps(RapidTestFormField.access_rapid_tests)}
                  />
                </Grid>
                <Grid item md={8}>
                  <ControlledDatePicker
                    fullWidth
                    disableFuture
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    inputVariant="outlined"
                    defaultValue={form.getValues().test_date}
                    value={form.watch(RapidTestFormField.test_date)}
                    onChange={date => {
                      form.setValue(RapidTestFormField.test_date, date, {
                        shouldValidate: true,
                        shouldDirty: true,
                      });
                    }}
                    {...getFieldProps(RapidTestFormField.test_date)}
                  />
                </Grid>
                <Grid item md={8}>
                  <RadioGroup
                    row={false}
                    {...getFieldProps(RapidTestFormField.test_result)}
                  />
                </Grid>
                <Grid item md={8}>
                  <RadioGroup
                    row={false}
                    {...getFieldProps(
                      RapidTestFormField.symptoms_in_last_14_days
                    )}
                  />
                </Grid>
                <Grid item md={8}>
                  <RadioGroup
                    row={false}
                    {...getFieldProps(RapidTestFormField.shared_test_results)}
                  />
                </Grid>
                <Grid item md={8}>
                  <RadioGroup
                    row={false}
                    {...getFieldProps(RapidTestFormField.other_covid_test)}
                  />
                </Grid>
                {receivedFollowUpTest(otherCovidTestField) && (
                  <>
                    <Grid item md={8}>
                      <ControlledDatePicker
                        fullWidth
                        disableFuture
                        disableToolbar
                        variant="inline"
                        format="MM/dd/yyyy"
                        inputVariant="outlined"
                        defaultValue={form.getValues().other_test_date}
                        value={form.watch(RapidTestFormField.other_test_date)}
                        onChange={date => {
                          form.setValue(
                            RapidTestFormField.other_test_date,
                            date,
                            { shouldValidate: true, shouldDirty: true }
                          );
                        }}
                        {...getFieldProps(RapidTestFormField.other_test_date)}
                      />
                    </Grid>
                    <Grid item md={8}>
                      <RadioGroup
                        row={false}
                        {...getFieldProps(RapidTestFormField.other_test_result)}
                      />
                    </Grid>
                  </>
                )}
                <Grid item md={8}>
                  <Select
                    showBlankDefault
                    variant="outlined"
                    options={ageOptions}
                    {...getFieldProps(RapidTestFormField.age)}
                  />
                </Grid>
                <Grid item md={8}>
                  <StatesAndProvincesSelect
                    {...getFieldProps(RapidTestFormField.state_or_province)}
                  />
                </Grid>
                <Grid item md={8}>
                  <TextField
                    fullWidth
                    type="text"
                    color="primary"
                    variant="outlined"
                    error={!!form.errors.zipcode}
                    name={RapidTestFormField.zipcode}
                    disabled={stateOrProvinceField === undefined}
                    helperText={getFieldError(RapidTestFormField.zipcode)}
                    inputRef={form.register(
                      validationSchema.get(RapidTestFormField.zipcode)
                    )}
                    label={t(
                      `${tPath}.form.${RapidTestFormField.zipcode}.label`,
                      {
                        context: getCountryByStateOrProvince(
                          stateOrProvinceField
                        ),
                      }
                    )}
                    style={{
                      backgroundColor:
                        stateOrProvinceField === undefined
                          ? 'transparent'
                          : 'white',
                    }}
                  />
                </Grid>
              </>
            )}
            <Grid item md={8}>
              <Button
                size="large"
                type="submit"
                color="primary"
                variant="contained"
                onClick={form.handleSubmit(onSubmit, invalidSubmit)}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Section>
  );
};
