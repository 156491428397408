import React from 'react';
import { useTranslation } from 'react-i18next';
import { RadioGroupProps } from '@material-ui/core';
import { Radio, RadioOptions } from '~/components/forms/fields/Radio';
import { RegisterOptions, Control, Controller } from 'react-hook-form';

interface Props extends RadioGroupProps {
  label: string;
  formControl: Control;
  errorMessage?: string;
  validationRules?: RegisterOptions;
}

export const RadioGroup: React.FC<Props> = React.forwardRef(
  ({ name, label, formControl, validationRules, errorMessage, row }, _) => {
    const { t } = useTranslation();

    const options: RadioOptions = t(`Testing.form.${name}.options`, {
      returnObjects: true,
    });

    return (
      <Controller
        name={name}
        control={formControl}
        rules={validationRules ?? {}}
        render={props => (
          <Radio
            {...props}
            row={row}
            name={name}
            label={label}
            options={options}
            errorMessage={errorMessage}
          />
        )}
      />
    );
  }
);
