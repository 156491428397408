/* eslint-disable camelcase */
import { getEnumKeys } from './helpers';
import { isFuture, isValid, differenceInDays } from 'date-fns';
import { usZipPattern, canadaPostalCodePattern } from '~/common/regex';
import { RegisterOptions, useForm, UseFormMethods } from 'react-hook-form';
import {
  AgeAnswer,
  YesOrNoAnswer,
  RapidTestForm,
  TestBrand,
  AccessToRapidTestAnswer,
  RapidTestFormField,
  OtherCovidTestAnswer,
  CovidTestResultAnswer,
  WasTestedForCovidAnswer,
  CanadaProvince,
  USState,
  SharedTestResultsAnswer,
} from './types';

interface UseRapidTestForm {
  form: UseFormMethods<RapidTestForm>;
  validationSchema: Map<RapidTestFormField, RegisterOptions>;
  wasTestedField: WasTestedForCovidAnswer;
  otherCovidTestField: OtherCovidTestAnswer;
  stateOrProvinceField: USState | CanadaProvince;
}

const DAY_DIFFERENCE_LIMIT = 7;

export const useRapidTestForm = (): UseRapidTestForm => {
  const form = useForm<RapidTestForm>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: {
      [RapidTestFormField.test_result]: null,
      [RapidTestFormField.test_date]: null,
      [RapidTestFormField.other_test_date]: null,
      [RapidTestFormField.symptoms_in_last_14_days]: null,
      [RapidTestFormField.other_covid_test]: null,
      [RapidTestFormField.other_test_result]: null,
      [RapidTestFormField.age]: AgeAnswer.EMPTY,
    },
  });

  const wasTestedField = form.watch(RapidTestFormField.was_tested);
  const otherCovidTestField = form.watch(RapidTestFormField.other_covid_test);
  const stateOrProvinceField = form.watch(RapidTestFormField.state_or_province);

  const wasTestedOptions = getEnumKeys(WasTestedForCovidAnswer);
  const testBrandOptions = getEnumKeys(TestBrand);
  const accessToTestOptions = getEnumKeys(AccessToRapidTestAnswer);
  const testResultOptions = getEnumKeys(CovidTestResultAnswer);
  const symptomsOptions = getEnumKeys(YesOrNoAnswer);
  const sharedTestResultsOptions = getEnumKeys(SharedTestResultsAnswer);
  const otherTestOptions = getEnumKeys(OtherCovidTestAnswer);
  const otherTestResultOptions = getEnumKeys(CovidTestResultAnswer);
  const ageOptions = getEnumKeys(AgeAnswer);
  const usStates = getEnumKeys(USState);
  const canadaProvinces = getEnumKeys(CanadaProvince);
  const usZipPatternRegex = new RegExp(usZipPattern, 'i');
  const caPostalCodePatternRegex = new RegExp(canadaPostalCodePattern, 'i');

  const validationSchema = new Map<keyof RapidTestForm, RegisterOptions>();

  validationSchema.set(RapidTestFormField.was_tested, {
    required: true,
    validate: {
      options: answer => {
        return wasTestedOptions.some(option => answer === option);
      },
    },
  });

  validationSchema.set(RapidTestFormField.test_brand, {
    required: wasTestedField === WasTestedForCovidAnswer.YES,
    validate: {
      options: answer => {
        return testBrandOptions.some(option => answer === option);
      },
    },
  });

  validationSchema.set(RapidTestFormField.access_rapid_tests, {
    required: wasTestedField === WasTestedForCovidAnswer.YES,
    validate: {
      options: answer => {
        return accessToTestOptions.some(option => answer === option);
      },
    },
  });

  validationSchema.set(RapidTestFormField.test_date, {
    required: wasTestedField === WasTestedForCovidAnswer.YES,
    validate: {
      valid: dateString => {
        return isValid(new Date(dateString));
      },
      notFuture: dateString => {
        return !isFuture(new Date(dateString));
      },
      notTooLongAgo: (dateString: string) => {
        return (
          differenceInDays(new Date(), new Date(dateString)) <=
          DAY_DIFFERENCE_LIMIT
        );
      },
    },
  });

  validationSchema.set(RapidTestFormField.test_result, {
    required: wasTestedField === WasTestedForCovidAnswer.YES,
    validate: {
      options: answer => {
        return testResultOptions.some(option => answer === option);
      },
    },
  });

  validationSchema.set(RapidTestFormField.symptoms_in_last_14_days, {
    required: wasTestedField === WasTestedForCovidAnswer.YES,
    validate: {
      options: answer => {
        return symptomsOptions.some(option => answer === option);
      },
    },
  });

  validationSchema.set(RapidTestFormField.shared_test_results, {
    required: wasTestedField === WasTestedForCovidAnswer.YES,
    validate: {
      options: answer => {
        return sharedTestResultsOptions.some(option => answer === option);
      },
    },
  });

  validationSchema.set(RapidTestFormField.other_covid_test, {
    required: wasTestedField === WasTestedForCovidAnswer.YES,
    validate: {
      options: answer => {
        return otherTestOptions.some(option => answer === option);
      },
    },
  });

  validationSchema.set(RapidTestFormField.other_test_date, {
    required: otherCovidTestField === OtherCovidTestAnswer.YES_PCR_TEST,
    validate: {
      valid: dateString => {
        return isValid(new Date(dateString));
      },
      notFuture: dateString => {
        return !isFuture(new Date(dateString));
      },
      notTooLongAgo: (dateString: string) => {
        return (
          differenceInDays(new Date(), new Date(dateString)) <=
          DAY_DIFFERENCE_LIMIT
        );
      },
    },
  });

  validationSchema.set(RapidTestFormField.other_test_result, {
    required: otherCovidTestField === OtherCovidTestAnswer.YES_PCR_TEST,
    validate: {
      options: answer => {
        return otherTestResultOptions.some(option => answer === option);
      },
    },
  });

  validationSchema.set(RapidTestFormField.age, {
    required: wasTestedField === WasTestedForCovidAnswer.YES,
    validate: {
      options: answer => {
        return ageOptions.some(option => answer === option);
      },
    },
  });

  validationSchema.set(RapidTestFormField.state_or_province, {
    required: wasTestedField === WasTestedForCovidAnswer.YES,
    validate: {
      options: answer => {
        return (
          usStates.some(option => answer === option) ||
          canadaProvinces.some(option => answer === option)
        );
      },
    },
  });

  validationSchema.set(RapidTestFormField.zipcode, {
    required: wasTestedField === WasTestedForCovidAnswer.YES,
    validate: {
      validPattern: value => {
        if (usStates.some(option => stateOrProvinceField === option)) {
          return usZipPatternRegex.test(value);
        }
        if (canadaProvinces.some(option => stateOrProvinceField === option)) {
          return caPostalCodePatternRegex.test(value);
        }
        return false;
      },
    },
  });

  return {
    form,
    validationSchema,
    wasTestedField,
    otherCovidTestField,
    stateOrProvinceField,
  };
};
